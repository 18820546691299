import React from 'react';

import Layout from '../../components/Layout';

const PlataformPage = () => {
  return (
    <Layout>
      <h1>Plataforma</h1>
    </Layout>
  );
};

export default PlataformPage;
